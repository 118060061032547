<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="email-container">
        <div class="input-container">
          <donate-input v-model="email" type="text" placeholder="E-MAIL" name="email" class="email-input" />
          <donate-input v-model="name" type="text" placeholder="NAME" name="f_1" class="email-input"/>
          <div v-if="error" class="error-block">{{ error }}</div>
        </div>
      </div>
      <button-arrow-pink style="margin-left: 0; margin-top: 1rem; margin-right: auto; background: none; border: 0.25rem solid #FCFAF7;"
                         class="button" :text="'Subscribe'"/>

<!--      <button class="submit-button" type="submit">Подписаться</button>-->
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import OutlineButtonArrowOrange from "@/components/buttons/outlineButtonArrowOrange.vue";
import OutlineButton from "@/components/buttons/outlineButton.vue";
import ButtonArrowPink from "@/components/buttons/buttonArrowPink.vue";
import DonateInput from "@/components/inputs/donateInput.vue";

export default {
  components: {DonateInput, ButtonArrowPink, OutlineButton, OutlineButtonArrowOrange},
  data() {
    return {
      email: '',
      name: '',
      error: null
    };
  },
  methods: {
    async submitForm() {
      try {
        let formData = new FormData();
        formData.append('email', this.email);
        formData.append('email', this.name);
        formData.append('charset', 'UTF-8');
        formData.append('default_list_id', '1');
        formData.append('overwrite', '2');
        formData.append('is_v5', '1');

        const response = await axios.post('https://cp.unisender.com/ru/subscribe?hash=6aweymf3hcqhaicyap9pu1w3mu15qkitwye9pn75ba5fgidyxbrdo', formData);

        if (response.status === 200) {
          this.email = '';
          this.error = null;
          alert('Подписка успешно оформлена!');
        } else {
          this.error = 'Что-то пошло не так. Пожалуйста, попробуйте позже.';
        }
      } catch (error) {
        this.error = 'Ошибка сети или сервера. Пожалуйста, попробуйте позже.';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-container {
  display: flex;
  width: 100%;
  font-size: 0;

  .email-label {
    font-size: 12px;
    padding: 0 0 5px;
    vertical-align: middle;
    box-sizing: border-box;
    width: 32%;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(34, 34, 34);

    b {
      font-size: 14px;
      font-family: Georgia;
      line-height: 10px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .email-input {
      margin-top: 1rem;
      width: auto;
      //border-radius: 3px;
      //font: 13px / 1 Arial, Helvetica;
      //padding: 0 8px;
      //width: 100%;
      //box-sizing: border-box;
      //border: 1px solid rgb(193, 201, 203);
      //background-color: rgb(255, 255, 255);
      //height: 30px;
    }

    .error-block {
      color: #ff592d;
      font: 11px/18px Arial;
    }
  }
}

.submit-button {
  background-color: rgb(136, 136, 136);
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
