<template>
  <div class="container">
    <h1>
      What else we do
    </h1>
    <div class="wrapper">
      <div class="card">
        <h2 class="title">
          Media work
        </h2>
        <p class="description">
          We do media-management work, create and edit content, design social media posts - all of this we do for the
          goal of raising awareness of our work and of the problems LGBTQ+ and BAME people face in the region we work
          with.
        </p>
      </div>
      <div class="card">
        <h2 class="title">
          Fundraising
        </h2>
        <p class="description">
          We do crowdfunding, hold fundraising events in order to gather enough resources to continue our work helping
          LGBTQ+ and BAME people. Only thanks to the support of individual donors such as yourself we are able to help
          the people in need.
        </p>
      </div>
      <div class="card">
        <h2 class="title">
          Events
        </h2>
        <p class="description">
          We organize and host awareness-raising events together with our partners in order to raise awareness of and
          let people understand the specific challenges LGBTQ+ and BAME people face in the region as well as to build
          around us a community of our supporters.
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "what-else"
})
</script>

<style scoped lang="scss">

.container {
  h1 {
    color: var(--pink, #EC408D);

    /* title */
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 120% */
    text-transform: uppercase;
    text-align: start;
    margin-left: 4.375rem;;
  }

  .wrapper {
    display: flex;
    gap: 3.417rem;
    margin-top: 2.5rem;

    .card {
      border: 4px solid var(--yellow, #FDB643);
      padding: 2rem;
      text-align: start;
      flex: 1;

      .title {
        color: var(--black, #1B1B1E);
        /* sub title */
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        text-transform: uppercase;
      }

      .description {
        color: var(--black, #1B1B1E);

        /* text */
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 136.364% */
      }

    }
  }
}

@media (max-width: 978px) {
  .container {
    .wrapper {
      flex-direction: column;

      .card{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

</style>