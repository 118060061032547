<template>
  <button>{{ text }}</button>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "donateButton",
  props: ["text"]
})
</script>


<style scoped lang="scss">
button {
  padding: 0.5rem 1.25rem;
  border: 0.125rem solid var(--white, #FCFAF7);
  background: none;
  color: var(--white, #FCFAF7);
  /* menu */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-transform: uppercase;
  cursor: pointer;
}
.active {
  background: var(--white, #FCFAF7);
  color: #1B1B1E;
}

</style>