<template>
  <div class="container">
    <h1 class="first-title">
      who we are
    </h1>
    <div class="row">
      <p>
        We are a black queer-run independent team of volunteers from all over the world. With your support we help
        LGBTQ+ and BAME from Ukraine, Russia, Belarus and other “post-soviet” countries whose lives have been affected
        by war and/or political repressions get to safety by providing consultations, purchasing tickets, finding free
        accommodation and providing humanitarian aid.
      </p>
      <p>
        Like any catastrophe, the war affects the most vulnerable persons the most. This means that marginalized
        communities, including BAME and LGBTQ+ people, are facing disproportionately high risks, yet their troubles are
        often rendered invisible. But because our own team comprises queer and BAME people we see the specific
        challenges our beneficiaries face and understand how to help them.
      </p>

    </div>

    <h1 class="second-title">
      what we do
    </h1>

    <div class="row">
      <div class="card">
        <div class="wrapper">
          <h3>We help queer and BAME people affected by the war in Ukraine</h3>
          <p>
            Providing direct aid to those in need; for those located in Ukraine, this includes relocation to safety
            (either within the country or abroad), housing, consultations, and other forms of assistance on a case-by-case
            basis
          </p>
        </div>
        <div @click="pushRoute('about')">
          <outline-button-arrow class="button" :text="'see more'"/>
        </div>

      </div>

      <div class="card">
        <div class="wrapper-pink">
          <h3>
            For LGBTQ+ and BAME people who are at risk of persecution in Russia and Belarus
          </h3>
          <p>
            We provide guidance for relocation and the asylum process. We also run a campaign to help Russian transgender
            people change their legal gender
          </p>
        </div>
        <a style="text-decoration: none" target="_blank" href="https://airtable.com/shrqFnp98dhiIDrIE">
          <button-arrow-pink class="button" :text="'get help'"/>
        </a>

      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";
import OutlineButtonArrow from "@/components/buttons/outlineButtonArrowOrange.vue";
import ButtonArrowPink from "@/components/buttons/buttonArrowPink.vue";

export default {
  name: "whoWeAre",
  components: {ButtonArrowPink, OutlineButtonArrow, OutlineButton},
  methods: {
    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },
  }
}
</script>

<style scoped lang="scss">

p, h1, h3 {
  margin: 0;
}



.container {
  margin-top: 0.9375rem;
  border: 0.25rem solid #FDB643;
  padding: 3.75rem 4.375rem;
  border-radius: 0.875rem 0.875rem 0 0 ;
  text-align: start;

  .first-title {
    color: var(--yellow, #FDB643);
    /* title */
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
    margin-bottom: 1.875rem;
  }

  .second-title {
    @extend .first-title;
    color: var(--pink, #EC408D);
    margin-top: 5.625rem;
  }

  .row {
    display: flex;
    gap: 2.5rem;


    p {
      color: var(--black, #1B1B1E);

      /* text */
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: start;
      flex: 1 0 50%;
    }

    .card {
      flex: 1 1 50%;
      .wrapper {
        border: 0.25rem solid var(--yellow, #FDB643);
        padding: 1.5rem;
        margin-bottom: 1.875rem;

        &-pink {
            @extend .wrapper;
            border: 0.25rem solid var(--pink, #EC408D);
        }

        h3 {
          color: var(--black, #1B1B1E);
          /* sub title */
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.25rem;
          text-transform: uppercase;
          margin-bottom: 0.75rem;
        }
      }
      .button {
        margin-left: auto;
      }

      @media (max-width: 978px) {
        .button {
          width: 100%;
        }
      }
    }


  }

  @media (max-width: 978px) {
    .row {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 978px) {
  .container {
    border: none;
    padding: 1.875rem 2.187rem;
  }
}

</style>