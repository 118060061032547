<template>
  <div class="container">
    <div class="text-container">
      <h1>
        reports on our activities
      </h1>
      <h2 @click="downloadReport">
        2022 - full year reports
      </h2>
      <p>
        If you want to find out more about our work in 2022, please read our annual report covering what we do, the
        number of people we helped, events we organized, our partnerships and much more.
      </p>


    </div>

    <div class="image-container">
      <img src="@/assets/reports-page/logo.png">
    </div>

  </div>
</template>

<script>
import OutlineButton from "@/components/buttons/outlineButton.vue";
import reportPdf from '@/assets/report.pdf';

export default {
  name: "reportOnOurActivities",
  components: {OutlineButton},
  data() {
    return {
      reportUrl: reportPdf
    }
  },
  methods: {
    downloadReport() {
      window.open(this.reportUrl, '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  display: flex;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;

  .text-container {
    flex: 1 0 50%;


    h1 {
      color: var(--yellow, #FDB643);
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 120% */
      text-transform: uppercase;
      text-align: start;

    }

    h2 {
      color: var(--yellow, #FDB643);
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 152.941% */
      letter-spacing: -1.02px;
      text-decoration-line: underline;
      text-transform: uppercase;
      text-align: start;
      margin-top: 1.875rem;
      cursor: pointer;
    }

    p {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 136.364% */
      text-align: start;
      margin-top: 0.75rem;
    }

  }

  .image-container {
    flex: 1 0 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      //transform: translate(-50%, 50%);
    }

    @media (max-width: 1440px) {
      /* CSS styles for viewport width less than 1440px */
      img {
        width: 60%;
        height: auto;
      }
    }
  }

  @media (max-width: 978px) {
    .image-container {
      display: none;
    }
  }
}

</style>