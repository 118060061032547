<template>
  <div class="container">
    <h1>
      why lgbtq+ and bame* people
    </h1>

    <div class="wrapper">
      <div class="column">
        <h2>
          *Black, Asian & Minority Ethnic
        </h2>
        <img src="@/assets/family.png">
        <p>
          <span>
            —
          </span>
          <span>
            Ukrainian transgender women that haven’t had their documents changed now can’t leave the country due to the
            mobilization law
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
           Russian transgender women who haven’t yet changed their legal gender are at risk of being drafted to the criminal war
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
           Russian LGBTQ+ couples with kids are in danger of their kids being taken away by the state because of the parents’ sexual orientation and/or gender identity
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            In Russia and Belarus the repressions against the queer activists have intensified against the backdrop of the full-scale war. In 2023 Russian authorities have opened several criminal cases under the new “gay propaganda” law – the first victims were migrant transgender sex workers as well as just prominent LGBTQ+ people noticed by the law-enforcement officers
          </span>
        </p>

      </div>
      <div class="column-right">
        <h2>
          Catastrophes, such as war, most impact those who are already vulnerable
        </h2>

        <p>
          <span>
            —
          </span>
          <span>
            Many BAME people who lived in Ukraine before the war are now being refused help in Europe as the European Temporary Protection Directive only covers citizens and permanent residents of Ukraine
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            Some of the BAME people are also LGBTQ+ and thus face dual discrimination which makes accessing vital resources in times of crisis even harder
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            Because of their vulnerable position, BAME people are also targeted by criminals and face dangers of extortion, trafficking or other crimes since they are less likely to seek help from authorities
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            Same-sex refugee couples are often unable to reunite because reunification procedures in the destination countries require marriage certificates which are impossible to obtain in the “post-soviet” countries that do not recognize same-sex marriage
          </span>
        </p>

        <img src="@/assets/lgbt.png">

      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "whyLGBTq"
})
</script>

<style scoped lang="scss">
.container {
  margin-top: 5.625rem;
  //padding-left: 3.125rem;
  //padding-right: 3.125rem;

  h1 {
    color: var(--pink, #EC408D);
    padding-left: 4.375rem;

    /* title */
    font-size: 3.125rem;
    //font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
  }
  @media (max-width: 1440px) {
    /* CSS styles for viewport width less than 1440px */
    h1 {
      padding-left: 0;
    }

  }

  .wrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 1.875rem;
    flex-wrap: wrap;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;



      h2 {
        color: var(--black, #1B1B1E);

        /* sub title */
        font-size: 1.75rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
        text-transform: uppercase;
        text-align: start;
        padding-left: 4.375rem;
      }

      img {
        margin-top: 2.75rem;
        max-width: 100%;
      }

      p {
        display: flex;
        gap: 1.2rem;
        padding-left: 4.375rem;
        margin-top: 1.875rem;

        color: var(--black, #1B1B1E);

        /* text */
        font-size: 1.375rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
        text-align: start;
      }

      hr {
        display: none;
      }

      @media (max-width: 1440px) {
        /* CSS styles for viewport width less than 1440px */
        p {
          padding-left: 0;

        }

        h2 {
          padding-left: 0;
        }

      }

      @media (max-width: 978px) {
        p {
          margin-bottom: 1.875rem;
        }

        hr {
          display: block;
          background: #FDB643;
          border: none;
          height: 0.25rem;
          width: 80%;
        }

        img {
          order: 1;
        }
      }

      &-right {
        @extend .column;

        p {
          padding-left: 0;
          padding-right: 4.375rem;
        }

        h2 {
          padding-left: 0;
          padding-right: 4.375rem;
        }

        @media (max-width: 1440px) {
          /* CSS styles for viewport width less than 1440px */
          p {
            padding: 0;
          }
          h2 {
            padding: 0;
          }

        }

      }
    }

    @media (max-width: 978px) {
      .column {
        flex: 1 0 100%
      }
    }
  }
}

@media (max-width: 978px) {
  .container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
</style>