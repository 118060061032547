<template>

  <div class="container">
    <div class="card left">

      <h1>
        to partner up <br> with us
      </h1>

      <p>
        If you want to partner up with us, get a comment from us for your material, write an article about us or somehow
        collaborate, please reach out to us here:
      </p>

      <a style="text-decoration: none" href="mailto:media@queersvit.org">
        <button-arrow-pink @click="" style="margin-left: 0; margin-right: auto; margin-top: 1.75rem" class="button"
                           :text="'media@queersvit.org'"/>
      </a>


    </div>

    <div class="card right">
      <h1>
        Subscribe to our newsletter
      </h1>

      <p>
        I agree to the processing of my <u>personal data</u>
      </p>
          <unisender/>
<!--      <button-arrow-pink style="margin-left: 0; margin-right: auto; background: none; border: 0.25rem solid #FCFAF7;"-->
<!--                         class="button" :text="'e-mail'"/>-->
    </div>

  </div>

</template>

<script>
import unisender from "@/components/mainPage/unisender.vue";
import ButtonArrowPink from "@/components/buttons/buttonArrowPink.vue";

export default {
  name: "subscription",
  components: {ButtonArrowPink, unisender}
}
</script>

<style scoped lang="scss">

.container {
  margin-top: 7.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;

  .card {
    flex: 1;
    padding: 1.875rem 4.5rem;
    text-align: start;
    box-sizing: border-box;
    //min-width: 100%;
  }

  .left {
    border: 0.25rem solid var(--pink, #EC408D);

    h1 {
      color: var(--pink, #EC408D);
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.75rem;
      text-transform: uppercase;
    }

    p {
      margin-top: 0.75rem;
      color: var(--black, #1B1B1E);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
    }

  }

  .right {
    background: var(--yellow, #FDB643);
    display: flex;
    flex-direction: column;

    h1 {
      color: var(--white, #FCFAF7);
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.75rem;
      text-transform: uppercase;
    }

    p {
      color: var(--white, #FCFAF7);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      margin-top: auto;
      margin-bottom: 1rem;
    }
  }

}

@media (max-width: 978px) {
  .container {
    .card {
      padding: 1.167rem;
      margin-left: 10px;
      margin-right: 10px;

      h1 {
        /* Mobile/title mobile */
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px; /* 118.75% */
        text-transform: uppercase;
      }

      button {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 136.364% */
        text-transform: uppercase;
      }
    }
  }
}

</style>