<template>
  <div class="container">
    <div class="text-container">
      <h1>
        Thank you for your support!
      </h1>
      <p class="description">
        Share the news about supporting us with your friends on social media! To do this you can press the buttons below:
      </p>

      <div class="social-media">
        <div class="img-wrapper">
          <img src="@/assets/thank-you-page/telegram.png">
        </div>
        <div class="img-wrapper">
          <img src="@/assets/thank-you-page/instagram.png">
        </div>
        <div class="img-wrapper">
          <img src="@/assets/thank-you-page/facebook.png">
        </div>
        <div class="img-wrapper">
          <img src="@/assets/thank-you-page/twitter.png">
        </div>
      </div>
    </div>

    <div class="image-container">
      <img src="@/assets/thank-you-page/logo.png">
    </div>

  </div>
</template>

<script>
import OutlineButton from "@/components/buttons/outlineButton.vue";

export default {
  name: "thankYouComponent",
  components: {OutlineButton}
}
</script>


<style scoped lang="scss">

.container {
  display: flex;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;

  .text-container {
    flex: 1 0 50%;

    h1 {
      color: var(--white, #FCFAF7);
      font-size: 4.167rem;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 120% */
      text-transform: uppercase;
      margin: 0;
      text-align: start;

    }

    .description {
      margin-top: 0.833rem;
      color: var(--white, #FCFAF7);
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-align: start;
    }

    .button {
      margin-top: 2.5625rem;
      font-size: 2.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.25rem;
      letter-spacing: -0.0825rem;
      text-transform: uppercase;
    }

    .social-media {
      display: flex;
      margin-top: 3.5rem;
      gap: 2.917rem;

      .img-wrapper {
      }
    }

    @media (max-width: 978px) {
      .button {
        width: 100%;
      }
    }
  }

  .image-container {
    flex: 1 0 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      //transform: translate(-50%, 50%);
    }
    @media (max-width: 1440px) {
      /* CSS styles for viewport width less than 1440px */
      img {
        width: 60%;
        height: auto;
      }
    }
  }

  @media (max-width: 978px) {
    .image-container {
      display: none;
    }
  }
}

</style>