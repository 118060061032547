<template>
  <div class="container">
    <h1>
      ABOUT US
    </h1>
    <div class="text-container">
      <div class="block">
        <p>
          We are Queer Svit (Ukrainian: «Queer World»).<br><br>

          As a black queer-run independent team of volunteers Queer Svit emerged in March of 2022 in response to the
          full-scale Russian invasion of Ukraine in February of the same year. In the beginning there were 4 co-founders
          of our initiative: a couple of Black Russian queer people, a Ukrainian transgender woman and a Ukrainian queer
          blogger.<br><br>

          Anna-Maria, one of the co-founders as well as the current operational director of Queer Svit, recalls: «I
          remember how we went to protest, but it seemed as if it was not enough. So, on March 4th, we decided to do
          something more and came up with an idea of setting up Queer Svit — we made up the name, started designing, I
          started reaching out to LGBTQ+ organizations. We made a decision to help queer people in Ukraine, Russia and
          Belarus because we have friends from all of these countries that suffered from the war and its consequences».
        </p>
      </div>
      <div class="block last">
        <p>
          Queer Svit is still a smaller initiative – we can continue our work only thanks to your donations that we use
          to help LGBTQ+ and BAME from Ukraine, Russia, Belarus and other “post-soviet” countries whose lives have been
          affected by war and/or political repressions get to safety by providing consultations, purchasing tickets,
          finding free accommodation and providing humanitarian aid.<br><br>

          Below you can find out more about our work.
        </p>

      </div>
    </div>

  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "about-us"
})
</script>

<style scoped lang="scss">

.container {
  display: flex;
  flex-direction: column;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;


  h1 {
    color: var(--white, #FCFAF7);
    font-size: 90px;
    font-style: normal;
    font-weight: 600;
    line-height: 96px; /* 106.667% */
    letter-spacing: -2.7px;
    text-transform: uppercase;
    text-align: start;
  }

  .text-container {
    margin-top: 60px;
    display: flex;
    gap: 40px;

    .block {
      flex: 1;

      p {
        text-align: start;
        color: var(--white, #FCFAF7);
        /* text */
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

}

@media (max-width: 978px) {
  .container {
    margin-left: 2rem;
    margin-right: 2rem;
    .block {
      &.last{
        display: none;
      }
    }
  }

}

</style>