<template>
  <div class="container">
    <div>
      <p>
        We want to live in a world where there’s no homophobia, transphobia, racism, sexism or other forms of
        discrimination LGBTQ+ and BAME people face.<br><br>

        With you, we will create a world where human rights will be upheld and everyone will feel safe. Today, we are
        already creating support networks for the vulnerable groups and uniting our supporters, so that together we can
        build a more just and better tomorrow.<br><br>

        Anna Maria, Co-founder QueerSvit
      </p>
      <img class="background" src="@/assets/about-page/background1.svg">
      <img class="anna-maria" src="@/assets/about-page/anna-maria-new.png">
    </div>

  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "anna-maria"
})
</script>

<style scoped lang="scss">

.container {
  position: relative;
  display: flex;
  z-index: 1;
  padding-bottom: 5.833rem;
  p {
    width: 50%;
    margin-left: 4.375rem;
    margin-right: 4.375rem;
    margin-top: 5.625rem;
    padding-bottom: 5.125rem;
    text-align: start;
    color: var(--black, #1B1B1E);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 120% */
    letter-spacing: -0.5px;
  }
  .background {
    position: absolute;
    top: -20%;
    left: -2%;
    z-index: -1;
    height: 100%;
    max-width: 98%;
  }
  .anna-maria {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 40%;
    border-radius: 0.875rem;
  }
}

@media (max-width: 978px) {
  .container {
    background-image: url("@/assets/about-page/background1.svg");
    background-size: 800%;
    background-repeat: no-repeat;
    background-position: bottom;
    p {
      width: auto;
    }
  }

  .background {
    display: none;
  }
}


</style>