<template>

  <div class="container">

    <h1>
      our partners and friends
    </h1>

    <div class="wrapper">

      <div class="row">
        <img src="@/assets/partners/1.png">
        <img src="@/assets/partners/2.png">
        <img src="@/assets/partners/3.png">
        <img src="@/assets/partners/4.png">
        <img src="@/assets/partners/5.png">
      </div>

      <div class="row">
        <img src="@/assets/partners/6.png">
        <img src="@/assets/partners/7.png">
        <img src="@/assets/partners/8.png">
        <img src="@/assets/partners/9.png">
        <img src="@/assets/partners/10.png">
        <img src="@/assets/partners/11.png">
      </div>

      <div class="row persons">
        <div class="img-wrapper">
          <img src="@/assets/partners/12.png">
        </div>

        <div class="img-wrapper">
          <img src="@/assets/partners/13.png">
        </div>

        <div class="img-wrapper">
          <img src="@/assets/partners/14.png">
        </div>

        <div class="img-wrapper">
          <img src="@/assets/partners/15.png">
        </div>


      </div>

    </div>


  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ourPartners"
})
</script>

<style scoped lang="scss">

.container {
  margin-top: 7.5rem;
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  text-align: start;

  h1 {
    color: var(--pink, #EC408D);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 3.75rem;
    gap: 3.75rem;

    .row {
      display: flex;
      flex-wrap: wrap;

      img {
        margin: auto;
      }
    }

    .persons {
      gap: 3.8125rem;
      img {
        margin: 0;
      }
    }
  }

}

@media (max-width: 978px) {
  .container {
    margin-left: 0;
    margin-right: 0;

    h1 {
      margin-left: 2rem;
    }

    .row{
      &.persons {
        flex-wrap: nowrap;
        margin-left: 1.667rem;
        margin-right: 1.667rem;
        .img-wrapper {
          img {
            object-fit: contain;
            max-width: 100%;
          }
        }


      }
    }
  }
}

</style>