import { render, staticRenderFns } from "./weAreQueerSvit.vue?vue&type=template&id=3fd53916&scoped=true&"
import script from "./weAreQueerSvit.vue?vue&type=script&lang=js&"
export * from "./weAreQueerSvit.vue?vue&type=script&lang=js&"
import style0 from "./weAreQueerSvit.vue?vue&type=style&index=0&id=3fd53916&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd53916",
  null
  
)

export default component.exports