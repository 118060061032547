<template>
  <div class="wrapper">
    <div class="header">
      <div @click="showMenu" class="burger-icon">
        <img src="@/assets/icons/menu.png">
      </div>
      <div class="desktop" :class="{'dark' : dark}">
        <div class="buttons">
          <p @click="pushRoute('/')">
            home
          </p>
          <p @click="pushRoute('/about')">
            ABOUT US
          </p>
<!--          <p @click="pushRoute('/about')">-->
<!--            what we do-->
<!--          </p>-->
          <p @click="pushRoute('/reports')">
            reports
          </p>
        </div>

        <div class="social-media">
          <img v-if="!dark" @click="openSocial('https://t.me/queersvit')" src="@/assets/icons/telegram.png">
          <img v-else @click="openSocial('https://t.me/queersvit')" src="@/assets/icons/telegram-dark.png">


          <img  v-if="!dark" @click="openSocial('https://www.instagram.com/queer_svit/')" src="@/assets/icons/instagram.png">
          <img  v-else @click="openSocial('https://www.instagram.com/queer_svit/')" src="@/assets/icons/instagram-dark.png">

          <img v-if="!dark" @click="openSocial('https://www.facebook.com/QueerSvit-106953865358115')" src="@/assets/icons/facebook.png">
          <img v-else @click="openSocial('https://www.facebook.com/QueerSvit-106953865358115')" src="@/assets/icons/facebook-dark.png">


          <img v-if="!dark" @click="openSocial('https://twitter.com/QueerSvitUK')" src="@/assets/icons/twitter.png">
          <img v-else @click="openSocial('https://twitter.com/QueerSvitUK')" src="@/assets/icons/twitter-dark.png">
        </div>

        <div class="localization">
          <p>
            EN
          </p>
          <div @click="pushRoute('/donate')">
            <outline-button :dark="dark" :text="`donate`"></outline-button>
          </div>

        </div>
      </div>

    </div>

    <div @click="closeMenu" v-show="mobileMenu" class="mobile-background"/>
    <div v-show="mobileMenu" class="mobile-menu">
      <div class="wrapper">
        <div class="lang-wrapper">
          <p>
            EN
          </p>
          <p>
            UA
          </p>
          <p>
            RU
          </p>
        </div>
        <div class="menu-list">
          <p @click="pushRoute('/')">
            Home
          </p>
          <p @click="pushRoute('/about')">
            About us
          </p>
<!--          <p @click="pushRoute('/about')">-->
<!--            What we do-->
<!--          </p>-->
          <p @click="pushRoute('/reports')">
            reports
          </p>
          <div>
            <button @click="pushRoute('/donate')">donate</button>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";
import reportPdf from '@/assets/report.pdf';

export default defineComponent({
  name: "MenuTop",
  components: {OutlineButton},
  props: ['dark'],
  data() {
    return {
      mobileMenu: false,
      reportUrl: reportPdf
    }
  },
  methods: {
    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },

    openSocial(link){
      window.open(link, '_blank')
    },

    showMenu() {
      this.mobileMenu = true;
    },

    closeMenu(){
      this.mobileMenu = false;
    },
    downloadReport(){
      console.log('test')
      window.open(this.reportUrl, '_blank');
    }
  }
})
</script>

<style scoped lang="scss">


.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background: #FCFAF7;
  height: 100vh;
  min-width: 70%;
  max-width: 85%;
  border-radius: 0 0 0 0.875rem;
  padding-left: 40px;
  z-index: 1000;


  .wrapper {
    margin-left: 3.333rem;
    margin-top: 2.5rem;

    .lang-wrapper {
      display: flex;
      gap: 1.583rem;
      p {
        color: var(--black, #1B1B1E);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 110% */
        text-decoration-line: underline;
        text-transform: uppercase;
      }
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      text-align: start;
      margin-top: 5rem;

      p {
        color: var(--black, #1B1B1E);

        /* menu */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 110% */
        text-transform: uppercase;
      }

      button {
        color: var(--white, #FCFAF7);

        /* menu */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 110% */
        text-transform: uppercase;
        outline: none;
        border: none;
        padding: 8px 20px;
        background: var(--pink, #EC408D);
      }
    }
  }

}

.mobile-background {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background: black;
  height: 100vh;
  width: 100%;
  opacity: 0.3;
}


.header {

  .burger-icon {
    display: none;
    text-align: end;
    padding-right: 1.667rem;
  }


  .desktop {

    display: flex;
    color: var(--white, #FCFAF7);
    div {
      margin: auto auto auto 4.375rem;
    }

    &.dark{
      color: #1B1B1E;
    }

    .buttons {
      display: flex;
      gap: 3.125rem;

      p {
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.375rem;
        cursor: pointer;
      }
    }

    .social-media {
      display: flex;
      gap: 1.125rem;

      img {
        cursor: pointer;
      }

    }

    .localization {
      display: flex;
      gap: 1.9375rem;

      p {
        font-size: 1.25rem;
        line-height: 1.375rem;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin: auto;
      }
    }
  }

  @media (max-width: 978px) {
    /* CSS styles for viewport width less than 1440px */
    .desktop {
      display: none;
    }

    .mobile-menu {
      display: block;
    }

    .mobile-background {
      display: block;
    }

    .burger-icon {
      display: block;
    }

  }


}

@media (max-width: 978px) {
  /* CSS styles for viewport width less than 1440px */

  .mobile-menu {
    display: block;
  }

  .mobile-background {
    display: block;
  }

  .burger-icon {
    display: block;
  }

}



</style>