<template>
  <div class="container">
    <div class="text-container">
      <h1>
        Hi, we are Queer Svit
      </h1>
      <div @click="pushRoute">
        <outline-button class="button" :text="'support us'"/>
      </div>


    </div>

    <div class="image-container">
      <div>
        <img src="@/assets/we-are.png">
        <img class="asterisk" src="@/assets/asterisk.png">
      </div>

    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";

export default defineComponent({
  name: "weAreQueerSvit",
  components: {OutlineButton},
  methods: {
    pushRoute(){
      this.$router.push("donate")
    }
  }
})
</script>

<style scoped lang="scss">

.container {
  display: flex;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;

  .text-container {
    flex: 1 0 50%;


    h1 {
      color: var(--white, #FCFAF7);
      font-size: 5.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: 6rem;
      letter-spacing: -0.16875rem;
      text-transform: uppercase;
      margin: 0;
      text-align: start;

    }

    .button {
      position: relative;
      margin-top: 2.5625rem;
      font-size: 2.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.25rem;
      letter-spacing: -0.0825rem;
      text-transform: uppercase;
      cursor: pointer;
    }

    .button:before{
      position: absolute;
      content: "";
      top:0;
      left: 0;
      height: 4.667rem;
      width: 4.667rem;
      background-image: url("@/assets/home-page/decorator1.png");
      background-repeat: no-repeat;
      transform: translate(-50%, -30%);
      z-index: 1;
    }

    @media (max-width: 978px) {
      .button {
        width: 100%;
      }
    }
  }

  .image-container {
    flex: 1 0 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      //transform: translate(-50%, 50%);

      &.asterisk {
        left: 25%;
        top: 90%;
      }
    }

    @media (max-width: 1440px) {
      /* CSS styles for viewport width less than 1440px */
      img {
        width: 60%;
        height: auto;
      }
    }
  }

  @media (max-width: 978px) {
    .image-container {
      display: none;
    }
  }
}

</style>