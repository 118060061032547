<template>

  <div class="container">
    <div class="col">
      <p>
        Queer Svit, z. s., registration number - пока что его нет-
      </p>

      <p class="pointer">
        media@queersvit.org
      </p>

      <p class="pointer" style="margin-top: 2.625rem">
        Founding documents
      </p>

      <p class="pointer">
        Privacy policy
      </p>

      <p class="pointer">
        Disclaimer
      </p>

<!--      <p class="pointer">-->
<!--        Manage cookies-->
<!--      </p>-->

    </div>

    <div  class="col">
      <p @click="pushRoute('/about')" class="pointer">
        About us
      </p>

      <p @click="pushRoute('/donate')" class="pointer">
        Donate
      </p>

      <p class="pointer">
        Our mission
      </p>

      <p @click="pushRoute('/donate')" class="pointer">
        How we help
      </p>

      <p @click="openSocial('https://airtable.com/shrqFnp98dhiIDrIE')" class="pointer" style="margin-top: auto">
        Get help
      </p>

      <p @click="openSocial('https://airtable.com/appHf5kD8pScXJuvL/shrn70IlugD3Cw70x')" class="pointer">
        Become a volunteer
      </p>

    </div>

    <div class="col">

      <p @click="openSocial('https://www.instagram.com/queer_svit/')" class="pointer">
        Instagram
      </p>

      <p @click="openSocial('https://www.facebook.com/profile.php?id=100082989205807')" class="pointer">
        Facebook
      </p>

      <p @click="openSocial('https://twitter.com/QueerSvit')" class="pointer">
        Twitter ru
      </p>

      <p @click="openSocial('https://twitter.com/QueerSvitUK')" class="pointer">
        Twitter eng
      </p>

      <p @click="openSocial('https://t.me/queersvit')" class="pointer">
        Telegram
      </p>

    </div>

  </div>

</template>

<script>

export default {
  name: "Footer",
  methods: {
    openSocial(link){
      window.open(link, '_blank')
    },

    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },
  }
}
</script>

<style scoped lang="scss">

.container {
  display: flex;
  padding: 3.75rem 4.375rem;
  background: linear-gradient(to right, #EC408D, #FDB643);
  border-radius: 0.875rem 0.875rem 0 0  ;
  margin-top: 7.5rem;
  gap:2.5rem;

  .col {
    flex: 1;
    gap:0.75rem;
    text-align: start;
    display: flex;
    flex-direction: column;

    p {
      color: var(--white, #FCFAF7);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;

      &.pointer {
        cursor: pointer;
      }
    }

  }
}

@media (max-width: 978px) {
  .container {
    flex-wrap: wrap;
    .col {
      flex: 0 0 40%;
    }
  }
}

</style>