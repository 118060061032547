<template>
  <div class="container">
    Queer Svit is still a smaller initiative – we can continue our work only thanks to your donations that we use to
    help LGBTQ+ and BAME from Ukraine, Russia, Belarus and other “post-soviet” countries whose lives have been affected
    by war and/or political repressions get to safety by providing consultations, purchasing tickets, finding free
    accommodation and providing humanitarian aid.

    Below you can find out more about our work.
  </div>

</template>

<script>
export default {
  name: "mobile-description"
}
</script>


<style scoped lang="scss">

.container {
  display: none;
  margin-top: 2.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
  color: var(--black, #1B1B1E);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

@media (max-width: 978px) {
  .container {
    display: block;
  }
}
</style>