<template>
  <div class="container">
    <h1>
      how we help
    </h1>

    <div class="text-block">
      <div class="column">
        <p>
          <span class="mobile-none">
            —
          </span>
          <span>
            Helping people inside Ukraine<br>
            <span class="description">
              We help with relocating to a safer part of Ukraine;<br>
              we also provide humanitarian help
            </span>
          </span>
        </p>
        <hr>
        <p>
          <span class="mobile-none">
            —
          </span>
          <span>
            Helping LGBTQ+ and BAME people from Russia, Belarus and other "post-soviet" countries<br>
            <span class="description">
              We come up with an optimal relocation route and provide consultations. In extreme cases we help with relocation to a safer place: we can buy tickets for you, support your fundraiser or open up a fundraiser for you on our social media
            </span>
          </span>
        </p>
      </div>
      <hr>
      <div class="column">
        <p>
          <span class="mobile-none">
            —
          </span>
          <span>
            Helping people leaving Ukraine<br>
            <span class="description">
                We create an optimal relocation route and provide consultations, purchase tickets, find temporary accommodation in Europe
            </span>
          </span>
        </p>
        <hr>
        <p>
          <span class="mobile-none">
            —
          </span>
          <span>
            Helping transgender people in Russia<br>
            <span class="description">
              We provide financial aid to transgender people from Russia with changing their documents
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "how-we-help"
})
</script>

<style scoped lang="scss">

.container {
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;

  hr {
    display: none;
    background: #FDB643;
    border: none;
    height: 0.25rem;
    width: 80%;
  }

  h1 {
    color: var(--yellow, #FDB643);
    /* title */
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem; /* 120% */
    text-transform: uppercase;
    text-align: left;
  }

  .text-block {
    display: flex;
    margin-top: 2.5rem;
    gap: 3.333rem;

    .column {
      flex: 1;
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      p {
        display: flex;
        gap: 1.917rem;
        color: var(--black, #1B1B1E);
        /* sub title */
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem; /* 128.571% */
        text-transform: uppercase;

        .description {
          color: var(--black, #1B1B1E);
          /* text */
          font-size: 1.375rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.875rem; /* 136.364% */

        }
      }
    }
  }
}

@media (max-width: 978px) {
  .container {
    .text-block {
      flex-direction: column;

    }

    hr {
      display: block;
    }

    .mobile-none {
      display: none;
    }
  }
}

</style>