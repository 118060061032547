<template>
  <div class="container">
    <h1>
      our team
    </h1>
    <p>
      Unfortunately, not all members of our team are able to talk openly about their work with us due to security
      concerns since they or their close ones remain in Russia and may become subject to state persecution. Here are
      some members of our team who are open about their work with Queer Svit.
    </p>

    <div class="wrapper">
      <div class="card">
        <div class="vertical-text">
          <p class="title">
            Anna-Maria
          </p>
          <p class="description">
            Co-founder, CEO
          </p>

        </div>
        <img src="@/assets/about-page/anna-maria.png">
      </div>

      <div class="card">
        <div class="vertical-text pink">
          <p class="title">
            Ollie
          </p>
          <p class="description">
            Head of marketing
          </p>

        </div>
        <img src="@/assets/about-page/ollie.png">
      </div>

      <div class="card">
        <div class="vertical-text pink">
          <p class="title">
            Suzy
          </p>
          <p class="description">
            Co-founder, graphic designer
          </p>

        </div>
        <img src="@/assets/about-page/suzy.png">
      </div>

      <div class="card">
        <div class="vertical-text">
          <p class="title">
            Alexandra
          </p>
          <p class="description">
            Case manager
          </p>

        </div>
        <img src="@/assets/about-page/alexandra.png">
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "our-team"
})
</script>


<style scoped lang="scss">
.container {
  margin-top: 10rem;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  text-align: start;

  h1 {
    color: var(--pink, #EC408D);

    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 120% */
    text-transform: uppercase;
  }

  p {
    margin-top: 2.5rem;
    color: var(--black, #1B1B1E);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    gap: 3.333rem;

    .card {
      flex: 1 0 calc(50% - 3.333rem);
      display: flex;
      margin: auto;
      overflow: hidden;

      .vertical-text {
        padding-bottom: 7.5rem;
        background: var(--yellow, #FDB643);
        writing-mode: vertical-rl;
        transform: rotate(180deg);

        &.pink {
          background: var(--pink, #EC408D);
        }


        .title {
          color: var(--white, #FCFAF7);

          /* button */
          font-size: 34px;
          font-style: normal;
          font-weight: 700;
          line-height: 52px; /* 152.941% */
          letter-spacing: -1.02px;
          text-transform: uppercase;
        }

        .description {
          color: var(--white, #FCFAF7);

          /* text */
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 136.364% */
        }
      }

      img {
        height: 28.333rem;
        width: 100%;
        object-fit: cover;
        object-position: 80% 0%;
      }
    }

  }

}

@media (max-width: 978px) {
  .container {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    .wrapper {
      flex-direction: column;
    }
  }
}

</style>