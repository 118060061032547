<template>
  <div>
    <div class="header-wrapper">
      <menu-top :dark="true"/>
      <report-on-our-activities/>
    </div>

    <help-us class="margin"/>
  </div>

</template>

<script>
import MenuTop from "@/components/MenuTop.vue";
import HelpUs from "@/components/aboutPage/help-us.vue";
import ReportOnOurActivities from "@/components/reportsPage/reportOnOurActivities.vue";

export default {
  name: "ReportsView",
  components: {ReportOnOurActivities, HelpUs, MenuTop}
}
</script>

<style scoped lang="scss">
.header-wrapper {
  border-radius: .875rem;
  border: 4px solid var(--yellow, #FDB643);
  padding: 1.5625rem 4.6875rem;
}
.margin {
  margin-top: 8rem;
}

</style>